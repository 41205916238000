<template lang="pug">
.content(
  v-infinite-scroll="fetchData",
  infinite-scroll-disabled="isLinePointBusy",
  :infinite-scroll-distance="scrollDistance",
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Row
      Col(span='24')
        Select.app-tool(
          v-model="status"
          @on-change="onStatusChange"
          filterable
        )
          Option(v-for="item in statusList" :value="item.value" :key="`status-${item.value}`") {{ item.label }}
        Input.app-tool(
          v-model="search"
          icon="ios-search"
          clearable
          :placeholder="$t('Search point')"
          @on-change='onSearchInputChange'
        )

        Button.app-tool-right(
          type="primary",
          icon="md-add",
          @click="showLinePointEditor"
        ) {{ $t('Add new') }}

    //- 主表單內容
    Row(:style="{marginTop: '16px'}")
      Col(span='24' v-if="linePointList.length || !linePointListLoading")
        LinePointTable(
          :list-data="linePointList",
        )

      Col(span='24', class="table-loading-col", v-if="linePointListLoading")
        Spin(fix)
          Icon(type="ios-loading", size=18 class="spin-icon")

    LinePointEditor(
      :form-data="linePointData"
      edit-type="new"
      :visible="linePointEditorVisible"
      :on-update="onLinePointCreate"
      :on-close="closeLinePointEditor"
    )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LinePointTable from './line-point-table'
import LinePointEditor from './line-point-editor'
import Scroll from '~m/scroll'
import to from 'await-to-js'

export default {
  name: 'linePoint-list',
  components: {
    LinePointTable,
    LinePointEditor
  },
  mixins: [Scroll],
  data () {
    return {
      statusList: [
        {
          label: this.$t('Coupon.All'),
          value: 'All'
        },
        {
          label: this.$t('Coupon.Enabled'),
          value: 'Enabled'
        },
        {
          label: this.$t('Coupon.Pending'),
          value: 'Pending'
        },
        {
          label: this.$t('Coupon.Used'),
          value: 'Used'
        }
      ],
      linePointData: null,
      linePointEditorVisible: false,
      status: 'All',
      search: ''
    }
  },

  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      this.resetSelector()
    }
  },

  computed: {
    ...mapGetters('linePoint', {
      linePointList: 'getListData',
      linePointListLoading: 'getLoadingStatus',
      hasNextLinePoint: 'hasNext'
    }),

    // 遊戲內容是否在讀取
    isLinePointBusy () {
      return this.linePointListLoading || !this.hasNextLinePoint
    },

    type () {
      const path = this.$route.path.split('/')
      return path[path.length - 1]
    }
  },
  methods: {
    ...mapActions('linePoint', {
      findLinePoint: 'find',
      createLinePoint: 'create',
      sendLinePoint: 'update',
      resetLinePoint: 'reset'
    }),

    onStatusChange (val) {
      let { path, query } = this.$route
      query.status = val
      this.updateRoute({ path, query })
    },

    updateRoute ({ path, query }) {
      this.$router.push({ path })
      this.$router.replace({ path, query })
    },

    // search input 用
    onSearchInputChange (event) {
      clearTimeout(this.delaySearch)
      this.delaySearch = setTimeout(() => {
        let { path, query } = this.$route
        query.search = this.search
        this.updateRoute({ path, query })
      }, 1000)
    },

    // 向 API 取得 data
    // infinity scroll 需綁定
    async fetchData (reset = false) {
      let { query } = this.$route
      let { search, status } = query
      search = search === '' ? null : search
      status = status === 'All' ? null : status

      if (reset) {
        this.resetLinePoint({ search, status })
      }
      if (this.isLinePointBusy) return
      this.findLinePoint({ search, status })
    },

    // 依據 route 重設所有過濾條件
    async resetSelector () {
      const { search, status } = this.$route.query
      this.search = search || ''
      this.status = status || 'All'
      this.fetchData(true)
    },

    showLinePointEditor () {
      const data = {
        point: null,
        urls: ['']
      }

      this.linePointData = data
      this.linePointEditorVisible = true
    },
    async onLinePointCreate () {
      if (!this.linePointData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeLinePointEditor()
      }

      const data = _.pick(this.linePointData, ['point', 'urls', 'expiredTime'])
      console.log(data)
      let [err] = await to(this.createLinePoint({ data }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Create line point error'))
      }
      this.$Message.success(this.$t('Create line point successful'))
      this.closeLinePointEditor()
      this.linePointData = null
      this.fetchData(true)
    },

    closeLinePointEditor () {
      this.linePointEditorVisible = false
      setTimeout(() => {
        this.linePointData = null
      }, 500)
    }
  },
  async mounted () {
    await this.resetSelector()
  },
  beforeDestroy () {
  }
}

</script>

<style lang="sass">
.sort-method
  float: left
  margin-left: 8px
</style>
