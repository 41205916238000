<template lang="pug">
  .table
    AppTable.app-table(:columns="columns" :data="listData" border)
        template(slot-scope="{ row, index }" slot="createdAt")
          span {{ timeFormat(row.createdAt) }}
        template(slot-scope="{ row, index }" slot="updatedAt")
          span {{ timeFormat(row.updatedAt) }}
        template(slot-scope="{ row, index }" slot="point")
          span {{ row.data.point }}
        template(slot-scope="{ row, index }" slot="url")
          span {{ row.data.url }}
        template(slot-scope="{ row, index }" slot="user")
          span(v-if="row.data.user") {{ row.data.user.name }}({{ row.data.user.username }})
          span(v-if="!row.data.user") --
        template(slot-scope="{ row, index }" slot="expiredTime")
          span {{ (row.data.expiredTime) ? timeFormat(row.data.expiredTime, 'YYYY-MM-DD HH:mm') : 'Not set' }}
        template(slot-scope="{ row, index }" slot="status")
          span(:class="getColor(row.status)") {{ $t(`Coupon.${row.status}`) }}

        template(slot-scope="{ row, index }" slot="actions")
          Button.actions(
            v-if="row.status === 'Enabled'"
            type="success"
            size="small"
            icon="md-create"
            @click="showLinePointSend(row)"
          )
          Button.actions(
            v-if="row.status !== 'Enabled'"
            type="success"
            size="small"
            icon="md-checkmark"
            :disabled="true"
          )

    LinePointSend(
      :form-data="linePointData"
      edit-type="edit"
      :visible="linePointEditorVisible"
      :on-update="onLinePointUpdate"
      :on-close="closeLinePointSend"
    )
</template>
<script>
import AppTable from '~c/app-table'
import Time from '~m/time'
import { mapActions, mapGetters } from 'vuex'
import LinePointSend from './line-point-send'
import to from 'await-to-js'

export default {
  name: 'line-point-table',
  mixins: [Time],
  components: {
    LinePointSend,
    AppTable
  },
  props: {
    listData: Array
  },
  data () {
    return {
      linePointData: null,
      linePointEditorVisible: false
    }
  },
  computed: {
    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ]),
    columns () {
      let columns = [
        {
          title: this.$t('Created at'),
          slot: 'createdAt',
          align: 'center'
        },
        {
          title: this.$t('Updated at'),
          slot: 'updatedAt',
          align: 'center'
        },
        {
          title: 'Key',
          key: 'key',
          align: 'center'
        },
        {
          title: this.$t('Point'),
          width: 50,
          slot: 'point',
          align: 'center'
        },
        {
          title: this.$t('Rewards'),
          width: 200,
          slot: 'url',
          align: 'center'
        },
        {
          title: this.$t('Draw user'),
          slot: 'user',
          align: 'center'
        },
        {
          title: this.$t('Expired at'),
          slot: 'expiredTime',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Status'),
          width: 60,
          slot: 'status',
          align: 'center'
        },
        // {
        //   title: this.$t('Actived'),
        //   width: 100,
        //   render: this.renderSwitch,
        //   align: 'center'
        // },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 60,
          align: 'center'
        }
      ]

      columns = columns.filter(c => c.remove !== true)
      return columns
    },

    type () {
      const path = this.$route.path.split('/')
      return path[path.length - 1]
    }
  },
  async mounted () {
  },
  methods: {
    ...mapActions('linePoint', {
      findLinePoint: 'find',
      linePointUpdate: 'update',
      resetLinePoint: 'reset'
    }),
    ...mapActions('animation', {
      findAllAnimation: 'findAll'
    }),
    getColor (status) {
      return status.toLowerCase()
    },
    showLinePointSend (row) {
      this.linePointData = { _id: row._id, operator: '', user: '', message: '', title: '' }
      this.linePointEditorVisible = true
    },
    async onLinePointUpdate () {
      let [err] = await to(this.linePointUpdate({ id: this.linePointData._id, data: this.linePointData }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Update linePoint error'))
      }
      this.$Message.success(this.$t('Update linePoint successful'))
      this.closeLinePointSend()
    },
    closeLinePointSend () {
      this.linePointEditorVisible = false
      setTimeout(() => {
        this.linePointData = null
      }, 500)
    },
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('linePoint/update', { id: row._id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' }, trueValue: 'Enabled' }),
        h('Icon', { slot: 'close', props: { type: 'md-close' }, falseValue: 'Disabled' })
      ])
    }
  }
}
</script>
<style lang="sass">
.enabled
  color: #19be6b
.pending
  color: #808695
.used
  color: #ed4014

</style>
