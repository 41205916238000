<template lang="pug">
  Modal(
    :mask-closable="true"
    :style="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
  )
    p(slot="header")
      span {{ $t('Send LINE POINTS') }}

    Form.modal-editor(
      :model="formData"
      ref="linePointEditorForm"
    )
      template(v-if="formData")
        .content-part
          FormItem(prop="user")
            Row
              Col.title(span="9")
                h3 {{$t('Operator')}}
              Col.val(span="14" offset="1")
                Select.app-tool(
                  v-model="formData.operator"
                  style="width:140px"
                  filterable
                )
                  Option(v-for="item in allOperators" :value="item.value" :key="item.value") {{ item.label }}

          FormItem(prop="user")
            Row
              Col.title(span="9")
                h3 {{$t('User')}}
              Col.val(span="14" offset="1")
                Select.app-tool(
                  v-model="formData.user"
                  style="width:140px"
                  filterable
                  :remote-method="findUserList"
                  :loading="onUserLoading"
                )
                  Option(v-for="item in userList" :value="item._id" :key="item._id") {{ item.name }}

          FormItem(prop="title")
            Row
              Col.title(span="9")
                h3 {{$t('Message title')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.title"
                  :placeholder="$t('Message title')"
                )

          FormItem(prop="message")
            Row
              Col.title(span="9")
                h3 {{$t('Message')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.message"
                  :placeholder="$t('Please input message')"
                  type="textarea"
                )

    .footer(slot='footer')
      Button(
        icon="md-close"
        @click="onClose"
        :disabled="isUpdating"
      ) {{ $t('Cancel') }}
      Button(
        icon="md-checkmark"
        type="primary"
        @click="handleSubmit"
        :loading="isUpdating"
      ) {{ $t('Confirm') }}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'line-point-editor',
  props: {
    formData: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  data () {
    return {
      isUpdating: false,
      operatorList: [],
      userList: [],
      onUserLoading: false
    }
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) return
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {
    // 嵌入營運商的 state 資料
    ...mapGetters('operator', {
      allOperators: 'getAllForSelector'
    })
  },
  methods: {
    ...mapActions('operator', { getOperators: 'findAll' }),
    ...mapActions('user', { findUser: 'find', resetUser: 'resetList' }),
    async visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        return this.onClose()
      }
      this.formData.isReady = true
    },
    async findUserList (search) {
      await this.resetUser()
      if (search) {
        this.onUserLoading = true
        const users = await this.findUser({ operator: this.formData.operator, search })
        this.userList = users.collection
        this.onUserLoading = false
      } else {
        this.userList = []
      }
    },
    handleSubmit () {
      this.$refs.linePointEditorForm.validate(valid => {
        if (!valid) return false
        this.isUpdating = true
        this.onUpdate()
      })
    }
  },
  async mounted () {
    await this.getOperators()
  }
}
</script>
<style lang="sass">
.full-width
  width: 100%
.linePoint
  padding: 0 10px
.uploader
    margin-right: 8px
    margin-bottom: 8px
    width: 100%

.upload-btn
  width: 130px
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.app-tool
  text-align: left
</style>
