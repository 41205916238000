<template lang="pug">
  Modal(
    :mask-closable="true"
    :style="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
  )
    p(slot="header")
      span {{ editType === 'new' ? $t('Add') : $t('Edit') }}

    Form.modal-editor(
      :model="formData"
      ref="linePointEditorForm"
      :rules="linePointEditorRule"
    )
      template(v-if="formData")
        .content-part
          FormItem(prop="point")
            Row
              Col.title(span="9")
                h3 {{$t('Point')}}
              Col.val(span="14" offset="1")
                InputNumber(
                  v-model="formData.point"
                  :step="5"
                  controls-outside
                )

          FormItem(prop="url")
            Row
              Col.title(span="9")
                h3 {{$t('Url')}}
              Col.val(span="14" :offset="idx > 0 ? 10 : 1" v-for="(url, idx) in formData.urls" :key="`idx-${idx}`")
                Input(
                  v-model="formData.urls[idx]"
                  :placeholder="$t('Please input url')"
                  style="margin-bottom: 8px"
                )
              Col.val(span="14" offset="10")
                Button(
                  icon="md-add"
                  type="success"
                  size="small"
                  @click="addUrl"
                )
          FormItem(prop="expiredTime")
            Row
              Col.title(span="9")
                h3 {{$t('Expired time')}}
              Col.val(span="10" offset="1")
                DatePicker.full-width.date-picker(
                  type="date"
                  v-model="formData.expiredTime"
                  :placeholder="$t('Input line expiredTime')"
                  @on-change="onTimePickerChange"
                )
              Col.val(span="4")
                TimePicker.full-width(
                  format="HH:mm"
                  v-model="formData.expiredTimePicker"
                  :placeholder="$t('Select time')"
                  @on-change="onTimePickerChange"
                )

    .footer(slot='footer')
      Button(
        icon="md-close"
        @click="onClose"
        :disabled="isUpdating"
      ) {{ $t('Cancel') }}
      Button(
        icon="md-checkmark"
        type="primary"
        @click="handleSubmit"
        :loading="isUpdating"
      ) {{ $t('Confirm') }}
</template>
<script>
import moment from 'moment'

export default {
  name: 'line-point-editor',
  props: {
    formData: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  data () {
    return {
      isUpdating: false
    }
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) return
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {
    linePointEditorRule () {
      return {
        point: [{ required: true, pattern: /[0-9]\d*/, message: this.$t('Input line point'), trigger: 'blur' }],
        expiredTime: [{ required: true, validator: this.verifyDate('expiredTime'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    async visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        return this.onClose()
      }
      this.formData.isReady = true
    },

    addUrl () {
      this.formData.urls.push('')
    },

    handleSubmit () {
      this.$refs.linePointEditorForm.validate(valid => {
        if (!valid) return false
        this.isUpdating = true
        this.onUpdate()
      })
    },

    onTimePickerChange (val) {
      let fields = ['expiredTime']
      for (let field of fields) {
        let timeField = `${field}Picker`
        let date = moment(this.formData[field]).format('YYYY-MM-DD')
        let time = this.formData[timeField]
        let dateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').toDate()
        this.formData[field] = dateTime
      }
    },

    verifyDate (field) {
      return (rule, value, callback) => {
        if (!value || !_.isDate(value)) {
          return callback(new Error(this.$t(`Input line points ${field}`)))
        }
        callback()
      }
    }
  },
  async mounted () {
  }
}
</script>
<style lang="sass">
.full-width
  width: 100%
.linePoint
  padding: 0 10px
.uploader
    margin-right: 8px
    margin-bottom: 8px
    width: 100%

.upload-btn
  width: 130px
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
</style>
